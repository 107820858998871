import React from "react";

function Pagination({ totalLength, pagination, setPagination }) {
  return (
    <div className="text-center">
      {20 < totalLength && pagination * 20 < totalLength && (
        <button
          aria-label="Indlæs flere"
          onClick={() => setPagination(pagination ? pagination + 1 : pagination + 2)}
          className="btn text-gray-900 border border-solid border-gray-900 w-64"
        >
          Se flere
        </button>
      )}
    </div>
  );
}

export default Pagination;
