import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Products from "../components/Products";
import SEO from "../components/SEO";

const ProteinPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSitePage(
        filter: { context: { productType: { eq: "proteintilskud" } } }
      ) {
        edges {
          node {
            context {
              productTitle
              productImage
              productPath
              productPrice
              productOldprice
              productLink
              productId
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Find tilbud på proteintilskud til din træning"
        description="Spar en masse penge og find de bedste priser på proteintilskud hos Billigform. Det behøver ikke at være dyrt, at komme i form."
      />
      <h1 className="font-bold text-xl">Proteintilskud til din træning</h1>
      <p className="max-w-xl pb-4">
        Proteintilskud er et must for sportsudøverer. Proteintilskud hjælper dig
        med at opnå den tilstrækkelige mængde protein, din krop skal bruge til
        at arbejde med genopbygningen af musklerne, for at opnå de optimale og
        synlige resultater på muskelmassen. Derudover kan det ses som et lille
        mellemmåltid
      </p>
      <Products data={data} />
      <div className="my-12">
        <h2 className="text-2xl font-bold mb-4">
          Den afgørende byggesten for dine muskler under træning
        </h2>
        <p>
          Når det kommer til at opbygge og styrke vores muskler, spiller protein
          en afgørende rolle. Proteiner er essentielle næringsstoffer, der
          fungerer som byggestenene i vores kroppe. Under træning er det vigtigt
          at forstå, hvorfor protein er så vigtigt for muskelvækst og
          restitution. Denne artikel vil udforske betydningen af protein og dets
          rolle i at opretholde og styrke musklerne.
        </p>

        <h2 className="text-2xl font-bold my-4">Protein og dets funktioner</h2>
        <p>
          Proteiner består af aminosyrer, der er de grundlæggende enheder, der
          bruges til at opbygge og reparere væv i vores kroppe. Når vi træner,
          bliver vores muskelfibre udsat for stress og mikroskopiske tårer.
          Protein spiller en afgørende rolle i reparationen af disse tårer og
          bidrager til væksten og opretholdelsen af vores muskler.
        </p>

        <h2 className="text-2xl font-bold my-4">Muskelreparation og vækst</h2>
        <p>
          Efter træning er vores kroppe i en tilstand, hvor de kræver øget
          proteinindtagelse for at reparere og genopbygge musklerne. Protein er
          afgørende for muskelreparation og vækst, da det leverer de nødvendige
          aminosyrer til denne proces. Uden tilstrækkelig mængde protein vil
          musklernes restitution blive forsinket, og muskelvækst kan blive
          hæmmet.
        </p>

        <h2 className="text-2xl font-bold my-4">
          Protein og muskelproteinsyntese
        </h2>
        <p>
          Muskelproteinsyntese er den proces, hvorved kroppen danner nye
          muskelproteiner. Under træning øges muskelproteinsyntesen, og det er
          vigtigt at have en tilstrækkelig mængde aminosyrer tilgængelige i
          kroppen for at understøtte denne proces. Proteinindtagelse før og
          efter træning har vist sig at stimulere muskelproteinsyntesen, hvilket
          hjælper med at fremme muskelreparation og vækst.
        </p>

        <h2 className="text-2xl font-bold my-4">Proteinbehov under træning</h2>
        <p>
          Det præcise proteinbehov under træning varierer afhængigt af flere
          faktorer, herunder individets vægt, træningsintensitet og mål.
          Generelt anbefales det dog, at personer, der træner regelmæssigt,
          sikrer en tilstrækkelig proteinindtagelse. Sportsfolk og dem, der
          ønsker at øge muskelmasse, kan have behov for en højere mængde protein
          end den gennemsnitlige person.
        </p>

        <h2 className="text-2xl font-bold my-4">Kilder til protein</h2>
        <p>
          For at opfylde proteinbehovet er det vigtigt at inkludere en bred
          vifte af proteinkilder i kosten. Nogle af de bedste kilder til protein
          omfatter kød, fisk, æg, mejeriprodukter, bælgfrugter, nødder og frø.
          Mange mennesker foretrækker også at supplere deres proteinindtag med
          proteinpulver eller andre snacks i form af proteinpandekager eller proteinbar.
        </p>
      </div>
    </>
  );
};

export default ProteinPage;
