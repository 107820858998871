import React from "react";

function Sorting({ sortedBy, setSorting }) {
  return (
    <div className="flex flex-wrap justify-between md:block mb-8">
      <button
        aria-label="Sorter efter relevans"
        onClick={() => setSorting("relevans")}
        className={`sort-btn mb-4 mx-1 md:w-auto btn lg:mr-4 ${
          sortedBy === "relevans" ? "blue" : "bg-gray-200"
        } ${sortedBy === "relevans" ? "text-white" : "text-black"}`}
      >
        Relevans
      </button>
      <button
        aria-label="Sorter efter laveste prise"
        onClick={() => setSorting("lowestPrice")}
        className={`sort-btn mb-4  mx-1 md:w-auto btn lg:mr-4 ${
          sortedBy === "lowestPrice" ? "blue" : "bg-gray-200"
        } ${sortedBy === "lowestPrice" ? "text-white" : "text-black"}`}
      >
        Laveste pris
      </button>
      <button
        aria-label="Sorter efter højeste pris"
        onClick={() => setSorting("highestPrice")}
        className={`sort-btn mb-4 mx-1 md:w-auto btn lg:mr-4 ${
          sortedBy === "highestPrice" ? "blue" : "bg-gray-200"
        } ${sortedBy === "highestPrice" ? "text-white" : "text-black"}`}
      >
        Højeste pris
      </button>
      <button
        aria-label="Vis kun tilbud"
        onClick={() => setSorting("offer")}
        className={`sort-btn mb-4 mx-1 md:w-auto btn lg:mr-4 ${
          sortedBy === "offer" ? "blue" : "bg-gray-200"
        } ${sortedBy === "offer" ? "text-white" : "text-black"}`}
      >
        Tilbud
      </button>
    </div>
  );
}

export default Sorting;
