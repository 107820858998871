import React, { useState } from "react";
import Product from "../Product";
import Sorting from "../Sorting";
import Pagination from "../Pagination";

function Products({ data }) {
  const [sortedBy, setSorting] = useState("relevans");
  const [pagination, setPagination] = useState(0);

  const handleSorting = (products) => {
    if (sortedBy === "relevans") {
      return products;
    }

    if (sortedBy === "lowestPrice") {
      return products.sort(
        (a, b) =>
          Number(a?.node?.context?.productPrice?.[0]) -
          Number(b?.node?.context?.productPrice?.[0])
      );
    }

    if (sortedBy === "highestPrice") {
      return products.sort(
        (a, b) =>
          Number(b?.node?.context?.productPrice?.[0]) -
          Number(a?.node?.context?.productPrice?.[0])
      );
    }

    if (sortedBy === "offer") {
      return products.filter(
        (prod) =>
          Number(prod?.node?.context?.productOldprice?.[0]) >
          Number(prod?.node?.context?.productPrice?.[0])
      );
    }

    return products;
  };

  const productsLength = handleSorting([...data.allSitePage.edges]).length;
  return (
    <div>
      <Sorting sortedBy={sortedBy} setSorting={setSorting} />
      <div
        className={`flex flex-wrap ${
          productsLength > 0 ? "justify-center" : ""
        }`}
      >
        {productsLength > 0 ? (
          handleSorting([...data.allSitePage.edges])
            .slice(0, pagination ? pagination * 20 : 20)
            .map(
              ({
                node: {
                  context: {
                    productTitle,
                    productImage,
                    productPath,
                    productPrice,
                    productOldprice,
                    productLink,
                    productId,
                    productDescription,
                  },
                },
              }) => (
                <Product
                  key={productId}
                  title={productTitle[0]}
                  image={productImage[0]}
                  productPath={productPath}
                  productPrice={productPrice}
                  productOldprice={productOldprice}
                  productLink={productLink[0]}
                  productId={productId}
                />
              )
            )
        ) : (
          <div>
            <h2 className="font-bold">Ingen produkter fundet</h2>
          </div>
        )}
      </div>
      {productsLength > 0 && (
        <Pagination
          totalLength={data.allSitePage.edges.length}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </div>
  );
}

export default Products;
